
        import * as module0 from '../assets/icons/apple-touch-icon.png';import * as module1 from '../assets/icons/browserconfig.xml';import * as module2 from '../assets/icons/favicon-16x16.png';import * as module3 from '../assets/icons/favicon-32x32.png';import * as module4 from '../assets/icons/favicon.ico';import * as module5 from '../assets/icons/safari-pinned-tab.svg';import * as module6 from '../assets/icons/site.webmanifest.html'
        const modules = {
            "../assets/icons/apple-touch-icon.png": module0,
            "../assets/icons/browserconfig.xml": module1,
            "../assets/icons/favicon-16x16.png": module2,
            "../assets/icons/favicon-32x32.png": module3,
            "../assets/icons/favicon.ico": module4,
            "../assets/icons/safari-pinned-tab.svg": module5,
            "../assets/icons/site.webmanifest.html": module6,
        };
        export default modules;
      